'use strict';

const galleries = [
    {
        title: 'Аминьевское ш., 7',
        images: [
            'pic/kosmos/render-1.jpg',
            'pic/kosmos/render-2.jpg',
            'pic/kosmos/render-3.jpg',
            'pic/kosmos/render-4.jpg',
            'pic/kosmos/render-5.jpg',
            'pic/kosmos/render-6.jpg',
        ],
        imageTitle: [],
    },
    {
        title: 'Жигулевская ул., 4',
        images: [
            'pic/jg/pic_1.jpg',
            'pic/jg/pic_2.jpg',
            'pic/jg/pic_3.jpg',
            'pic/jg/pic_4.jpg',
            'pic/jg/pic_5.jpg',
            'pic/jg/pic_6.jpg',
            'pic/jg/pic_7.jpg',
            'pic/jg/pic_8.jpg',
            'pic/jg/pic_9.jpg',
            'pic/jg/pic_10.jpg',
            'pic/jg/pic_11.jpg',
            'pic/jg/pic_12.jpg',
            'pic/jg/pic_13.jpg',
            'pic/jg/pic_14.jpg',
            'pic/jg/addPic_1.jpg',
            'pic/jg/addPic_2.jpg',
            'pic/jg/addPic_3.jpg',
            'pic/jg/addPic_4.jpg',
            'pic/jg/addPic_5.jpg'
        ],
        imageTitle: [],
    }
    // {
    //     title: 'Аминьевское ш., 7',
    //     images: [
    //         'pic/galleries/am7/am7-2.jpg',
    //         'pic/galleries/am7/am7-3.jpg',
    //         'pic/galleries/am7/am7-4.jpg',
    //         'pic/galleries/am7/am7-5.jpg',
    //         'pic/galleries/am7/am7-6.jpg',
    //         'pic/galleries/am7/am7-7.jpg',
    //         'pic/galleries/am7/am7-8.jpg',
    //         'pic/galleries/am7/am7-9.jpg',
    //         'pic/galleries/am7/am7-10.jpg',
    //     ],
    //     imageTitle: [],
    // },
    // {
    //     title: 'Аминьевское ш., 9',
    //     images: [
    //         'pic/galleries/am9/am9-3.jpg',
    //         'pic/galleries/am9/am9-4.jpg',
    //         'pic/galleries/am9/am9-7.jpg',
    //         'pic/galleries/am9/am9-6.jpg',
    //         'pic/galleries/am9/am9-5.jpg',
    //         'pic/galleries/am9/am9-8.jpg',
    //         'pic/galleries/am9/am9-9.jpg',
    //         'pic/galleries/am9/am9-10.jpg',
    //     ],
    //     imageTitle: [],
    // },
    // {
    //     title: 'Жигулевская ул., вл. 4к1',
    //     images: [
    //         'pic/galleries/jg1/jg1-1.jpg',
    //         'pic/galleries/jg1/jg1-2.jpg',
    //         'pic/galleries/jg1/jg1-3.jpg',
    //         'pic/galleries/jg1/jg1-4.jpg',
    //         'pic/galleries/jg1/jg1-5.jpg',
    //         'pic/galleries/jg1/jg1-6.jpg',
    //         'pic/galleries/jg1/jg1-7.jpg',
    //         'pic/galleries/jg1/jg1-8.jpg',
    //         'pic/galleries/jg1/jg1-9.jpg',
    //         'pic/galleries/jg1/jg1-10.jpg',
    //     ],
    //     imageTitle: [],
    // },
    // {
    //     title: 'Жигулевская ул., вл. 4к2',
    //     images: [
    //         'pic/galleries/jg2/jg2-1.jpg',
    //         'pic/galleries/jg2/jg2-2.jpg',
    //         'pic/galleries/jg2/jg2-3.jpg',
    //         'pic/galleries/jg2/jg2-4.jpg',
    //         'pic/galleries/jg2/jg2-5.jpg',
    //         'pic/galleries/jg2/jg2-6.jpg',
    //         'pic/galleries/jg2/jg2-7.jpg',
    //         'pic/galleries/jg2/jg2-8.jpg',
    //         'pic/galleries/jg2/jg2-9.jpg',
    //         'pic/galleries/jg2/jg2-10.jpg',
    //     ],
    //     imageTitle: [],
    // }
];

// функция удаления всех запятых из строки
const removeCommas = (string) => string.replace(/[,]+/g, " ").trim();

// функция отрисовки выпадающего списка
const drawGalleriesSelectList = () => {
    const galleryContainer = document.getElementById('galleryContainer');
    const galleryContainerHeader = document.getElementById('galleryContainerHeader');

    if (galleryContainerHeader) {
        const fillGalleriesSelectList = () => {
            const selectListItems = galleries.map((gallery, index) => `<li><a class="${index === 0 ? 'active' : ''}" data-index="${index}" href="javascript;">${gallery.title}</a></li>`).join();

            return removeCommas(selectListItems);
        }

        const galleriesSelect = `
        <div class="city_objects_filter gallery_select">
          <div class="col">
            <div class="dropdown_wrapp">
              <div class="dropdown_title_wrapp">
                <div class="dropdown_title">
                  <a href="#" class="inputA" target="_blank">${galleries[0].title}</a>
                </div>
              </div>
              <div class="dropdown_box">
                <ul>
                  ${fillGalleriesSelectList()}
                </ul>
              </div>
            </div>
          </div>
        </div>
    `;

        return galleryContainerHeader.insertAdjacentHTML('afterend', galleriesSelect);
    }
}

// функция отрисовки галереи и превью
const drawGallery = (index) => {
    const galleryWrapper = document.getElementById('gallery');

    if (galleryWrapper) {
        galleryWrapper.innerHTML = '';

        const fillGalleryThumbs = (array) => {
            const thumbListItems = array.map((item, index) => `<p class="${index === 0 ? 'active' : ''}"><img src="${item}" alt=""></p>`).join();

            return removeCommas(thumbListItems);
        }

        const fillGallery = (array) => {
            const galleryListItems = array.map(item => `<a href="${item}"><img src="${item}" alt=""></a>`).join();

            return removeCommas(galleryListItems);
        }

        const galleryThumbsWrapper = `
      <div class="card--imgs section8_cardImgs current">
        ${fillGalleryThumbs(galleries[index].images)}
      </div>
    `;

        const galleryImagesWrapper = `
        <div class="card--img cardImg section8_cardImg current">
          ${fillGallery(galleries[index].images)}
        </div>
    `;

        galleryWrapper.insertAdjacentHTML('afterbegin', galleryImagesWrapper);
        galleryWrapper.insertAdjacentHTML('beforeend', galleryThumbsWrapper);

        return initSlider();
    }
}

// функция инициализации слайдеров
const initSlider = () => {
    if ($('.section8_cardImg').hasClass('slick-initialized')) {
        $('.section8_cardImg').slick('unslick');
    }

    if ($('.section8_cardImgs').hasClass('slick-initialized')) {
        $('.section8_cardImgs').slick('unslick');
    }

    $('.section8_cardImg').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: true,
        fade: true,
        speed: 200,
        asNavFor: $('.section8_cardImgs'),
        appendDots: $('.numbers_promo2'),
        appendArrows: $('.count_promo_slides2'),
        prevArrow: '<button type="button" class="slide_btn2 prev_slide"><i class="icon-hover icon-arrovPrev"></i></button>',
        nextArrow: '<button type="button" class="slide_btn2 next_slide"><i class="icon-hover icon-arrovNext"></i></button>',
        customPaging: function (slider, i) {
            i = i + 1;
            return '<div class="current_slide_num2">' + i + '</div><div class="count_slides2">' + slider.slideCount + '</div>';
        },
        responsive: [{
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                fade: false,
                variableWidth: true,
                adaptiveHeight: false,
            }
        }],
    });
    $('.section8_cardImgs').slick({
        arrows: true,
        infinite: false,
        dots: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: $('.section8_cardImg'),
        focusOnSelect: true,
        lazyLoad: 'ondemand',
        prevArrow: '<div class="card--prew2"><i class="icon icon-upArrow"></i></div>',
        nextArrow: '<div class="card--next2"><i class="icon icon-bottomArrow"></i></div>',
        responsive: [{
            breakpoint: 1023,
            settings: {
                slidesToShow: 4,
            }
        },{
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
            }
        },{
            breakpoint: 479,
            settings: {
                slidesToShow: 2,
            }
        }],
    });




    //Slides for text
    var i = 0;
    $('.section8_text').each(function () {
        i++;
        $(this).attr('data-slick-index', i);
    });

    $('.section8_cardImg').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var nextSlideNum = nextSlide + 1;
        $('.section8_text').removeClass('current');
        $('.section8_text[data-slick-index="' + nextSlideNum + '"]').addClass('current');
    });
}

// запуск всего, что выше по готовности DOM
document.addEventListener("DOMContentLoaded", function() {
    // drawGalleriesSelectList();
    drawGallery(0);
});
